import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/bundle.css';
import { GorodOstrov } from './lib/gorodostrov';
import vueAwesomeSidebar from 'vue-awesome-sidebar'
import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'
import {useToast} from "vue-toastification";
require('dayjs/locale/ru');
const dayjs = require('dayjs')
var localeData = require('dayjs/plugin/localeData')
dayjs.extend(localeData)
dayjs.locale("ru");
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
let app = createApp(App);
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@/assets/material-icons.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure your
import { aliases, md } from 'vuetify/iconsets/md'
// Components
import DateFnsAdapter from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { ru } from 'vuetify/locale'
import { VDateInput } from 'vuetify/labs/VDateInput'
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
let customTheme = {
    dark : true,
    colors :  {
        primary: "#f44336",
        secondary: "#e91e63",
        accent: "#9c27b0",
        error: "#ff5722",
        warning: "#ff9800",
        info: "#607d8b",
        success: "#4caf50",
        surface : "#2f3349",
        'surface-bright': '#FFFFFF',
        'surface-light': '#EEEEEE',
        'surface-variant': '#c73b3b',
    },
}
const vuetify = createVuetify({
    icons: {
        defaultSet: 'md',
        aliases,
        sets: {
            md,
        },
    },
    date: {
        adapter: DateFnsAdapter,
        locale: {
            ru: ruRU,
        },
    },
    locale: {
        locale: 'ru',
        fallback: 'en',
        messages: { ru },
    },
    defaults: {
        VBtn: {
            color: 'primary',

        },
    },
    theme : {
        defaultTheme : 'customTheme',
        themes : {
            customTheme
        }
    },
    components : {
        VDateInput,
        ...components
    },
    directives,
})


/*eslint-disable*/
function toCase(str, choice) {

    var strPub = { // правила для окончаний
            "а": ["ы", "е", "у", "ой", "е"],
            "(ш/ж/к/ч)а": ["%и", "%е", "%у", "%ой", "%е"],
            "б/в/м/г/д/л/ж/з/к/н/п/т/ф/ч/ц/щ/р/х": ["%а", "%у", "%а", "%ом", "%е"],
            "и": ["ей", "ям", "%", "ями", "ях"],
            "ый": ["ого", "ому", "%", "ым", "ом"],
            "ое": ["ого",'ому','%', 'ым', 'ом'],
            "ие": ["я", "ю", "я", "ем", "е"],
            "й": ["я", "ю", "я", "ем", "е"],
            "о": ["а", "у", "%", "ом", "е"],
            "с/ш": ["%а", "%у", "%", "%ом", "%е"],
            "ы": ["ов", "ам", "%", "ами", "ах"],
            "ь": ["я", "ю", "я", "ем", "е"],
            "уль": ["ули", "уле", "улю", "улей", "уле"],
            "(ч/ш/д/т)ь": ["%и", "%и", "%ь", "%ью", "%и"],
            "я": ["и", "е", "ю", "ей", "е"]
        },
        cases = { // номера для падежей, не считая Именительный
            "р": 0,
            "д": 1,
            "в": 2,
            "т": 3,
            "п": 4
        },
        exs = { // исключения, сколько символов забирать с конца
            "ц": 2,
            'ое' : 2,
            'ие' : 2,
            "ок": 2
        },
        lastIndex,reformedStr,forLong,splitted,groupped,forPseudo;
    for(var i in strPub){
        if(i.length > 1 && str.slice(-i.length) == i){ // для окончаний, длиной >1
            lastIndex = i;
            reformedStr = str.slice(0, -lastIndex.length);
            break;
        }
        else if(/[\(\)]+/g.test(i)){
            i.replace(/\(([^\(\)]+)\)([^\(\)]+)?/g, function(a, b, c){
                splitted = b.split("/");
                for(var o = 0; o < splitted.length; o++){
                    groupped = splitted[o] + c;
                    strPub[groupped] = strPub[i];
                    if(str.slice(-groupped.length) == groupped){
                        for(var x = 0, eachSplited = strPub[groupped];x < eachSplited.length; x++){
                            eachSplited[x] = eachSplited[x].replace("%", splitted[o]);
                        }
                        reformedStr = str.slice(0, -groupped.length);
                        forPseudo = groupped;
                    }
                }
            })
        }
        else{ // дефолт
            lastIndex = str.slice(-1);
            reformedStr = str.slice(0, -(forPseudo || lastIndex).length);
        }
        if(/\//.test(i) && !(/[\(\)]+/g.test(i)) && new RegExp(lastIndex).test(i))forLong = i; // группированные окончания, разделающиеся слешем
        for(var o in exs)
            if(str.slice(-o.length) == o)reformedStr = str.slice(0, -exs[o]);
    }
    return reformedStr + strPub[(forPseudo || forLong || lastIndex)][cases[choice]].replace("%", lastIndex)
}

function toCaseMulti(word,choice){
    let result = [];
    for(let i of word.split(' ')){
        result.push(toCase(i,choice))
    }
    return result.join(' ')
}
import VueKonva from 'vue-konva';
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import { createVPhoneInput } from 'v-phone-input';
import { VAutocomplete } from 'vuetify/components';

const vPhoneInput = createVPhoneInput({
    enableSearchingCountry: true,
    countryLabel: 'Страна',

    defaultCountry : "ru",
    countryAriaLabel: ({ label }) => `Страна ${label}`,
    invalidMessage: ({ label, example }) => `${label} должен быть в формате (пример: ${example}).`,
});

app.component('VAutocomplete', VAutocomplete);
app.use(vPhoneInput);
app.config.globalProperties.$dayJS = dayjs;
app.config.globalProperties.$toCase = toCase;
app.config.globalProperties.$toCaseMulti = toCaseMulti;
app.config.globalProperties.$toast = useToast;
app.config.globalProperties.$localeData = dayjs.localeData();
app.config.globalProperties.$api = new GorodOstrov();
app.config.globalProperties.$userData = store.state.userData;
app.use(OneSignalVuePlugin,{
    appId: '7ccf3d45-c6a3-4044-99b6-f12145318149',
})
app.use(vueAwesomeSidebar)


app.use(Toast)
app.use(vuetify)
app.use(VueKonva)
app.use(Vue3Tour)
app.use(store).use(router).mount('#app')
