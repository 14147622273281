<template>
  <div class="home ostrov-container flex justify-center align-center">
      <div class="form" v-if="!$store.state.userData">
        
        <h1>ГОРОДОСТРОВ</h1>
        <a @click="$router.push('/signup')">Нет аккаунта?</a>
        <input type="text" ref="login" placeholder="Логин">
        <input type="password" ref="password" placeholder="Пароль">
        
        <v-btn @click="logIn">Войти</v-btn>
      </div>
      <div class="form" v-if="$store.state.userData">
        <h1>ГОРОДОСТРОВ</h1>
        <div class="user-card" @click="$router.push('/dashboard')">
          <span>Продолжить, как {{$store.state.userData?.fio }}</span>
        </div>
      </div>
    
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
  },
  data(){
    return {
      unauthorized : null,
      userData : {}
    }
  },
  methods : {
    async logIn(){
      let login = this.$refs.login.value
      let password = this.$refs.password.value;
      let req = await this.$api.endpoint("post","/auth/login", {
        username: login,
        password: password,
      })
      if(req.token){
        if(!req.data.verified){
          this.$toast().error("Доступ запрещен");
          this.$toast().error("Ваш аккаунт не верифицирован, дождитесь верификации")
        }
        else{
          localStorage.setItem("token",req.token);
          this.$store.state.userData = req.data;
          this.$router.push('/dashboard')
        }

      }
    }
  },
  
}
</script>
