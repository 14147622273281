import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'welcome',
    component: () => import('../views/welcome.vue')
  },
  {
    path: '/auth',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard/',
    name: 'dashboard',
    component: () => import('../views/dashboard/main.vue')
  },
  {
    path: '/serverfailure/',
    name: 'Serverfailure',
    component: () => import('../views/ServerFailure.vue')
  },
  {
    path: '/dashboard/updates/',
    name: 'updates',
    component: () => import('../views/dashboard/updates.vue')
  },
  {
    path: '/dashboard/champs/usp/',
    name: 'usp',
    component: () => import('../views/dashboard/champs/usp.vue')
  },
  {
    path: '/dashboard/champs/usp/second',
    name: 'usp2',
    component: () => import('../views/dashboard/champs/usp 2.vue')
  },
  {
    path: '/dashboard/control/server/',
    name: 'server',
    component: () => import('../views/dashboard/controls/server')
  },
  {
    path: '/dashboard/contracts',
    name: 'contracts',
    component: () => import('../views/dashboard/contracts.vue')
  },
  {
    path: '/dashboard/itnight/menu/',
    name: 'menui',
    component: () => import('../views/dashboard/itnight/Menu.vue')
  },
  {
    path: '/dashboard/itnight/orders/',
    name: 'ordersi',
    component: () => import('../views/dashboard/itnight/Orders.vue')
  },
  {
    path: '/dashboard/itnight/qrs/',
    name: 'qrsi',
    component: () => import('../views/dashboard/itnight/QRs.vue')
  },
  {
    path: '/dashboard/idea/',
    name: 'idea',
    component: () => import('../views/dashboard/idea.vue')
  },
  {
    path: '/dashboard/control/notify/',
    name: 'notify',
    component: () => import('../views/dashboard/controls/notify.vue')
  },
  {
    path: '/dashboard/emails/',
    name: 'emails',
    component: () => import('../views/dashboard/emails.vue')
  },
  {
    path: '/dashboard/book/taranai',
    name: 'booksdashboard',
    component: () => import('../views/dashboard/taranai.vue')
  },
  {
    path: '/dashboard/chatgpt/',
    name: 'chatgpt',
    component: () => import('../views/dashboard/chatgpt.vue')
  },
  {
    path: '/dashboard/book/saturn',
    name: 'satun',
    component: () => import('../views/dashboard/saturn.vue')
  },
  {
    path: '/dashboard/book/cabs',
    name: 'cabs',
    component: () => import('../views/dashboard/cabinet.vue')
  },
  {
    path: '/dashboard/events',
    name: 'events',
    component: () => import('../views/dashboard/events.vue')
  },
  {
    path: '/dashboard/users',
    name: 'users',
    component: () => import('../views/dashboard/users.vue')
  },
  {
    path: '/dashboard/learnbase',
    name: 'learnbase',
    component: () => import('../views/dashboard/learnbase.vue')
  },
  {
    path: '/dashboard/projects',
    name: 'projects',
    component: () => import('../views/dashboard/projects.vue')
  },
  {
    path: '/dashboard/mails',
    name: 'mails',
    component: () => import('../views/dashboard/mails.vue')
  },
  {
    path: '/dashboard/media',
    name: 'socials',
    component: () => import('../views/dashboard/socials.vue')
  },
  {
    path: '/dashboard/time',
    name: 'time',
    component: () => import('../views/dashboard/timeecit.vue')
  },
  {
    path: '/signUp',
    name: 'register',
    component: () => import('../views/Register.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
