<template>
    <VueAwesomeSideBar v-model:miniMenu="miniMenu"
      v-model:collapsed="scollapsed" dark  v-if="$store.state.userData != null" @update:collapsed="scollapsed = !scollapsed" :menu="menu" vueRouterEnabel>

        <template v-slot:header>
          <v-container class="logo-container">
            <div class="logo" v-if="!miniMenu">
              <span>эко-система</span>
              <h1>ГОРОД ОСТРОВ</h1>
            </div>

            <v-icon class="sidebar-minimize-btn" v-if="miniMenu" @click="miniMenu = false" icon="radio_button_unchecked"></v-icon>
            <v-icon class="sidebar-minimize-btn" v-else icon="radio_button_checked" @click="miniMenu = true"></v-icon>
          </v-container>

        </template>

        <template v-slot:footer v-if="!scollapsed">
        <v-container v-if="!miniMenu" >

<!--          <div class="user-card">-->
<!--            <span class="text-body-2">{{$store.state.userData.fio}}</span>-->
<!--            <v-btn class="logout-btn" block size="small" variant="flat" prepend-icon="logout" @click="logOut()">Выйти</v-btn>-->
<!--          </div>-->
          <v-container
              fluid
          >
            <v-row justify="center" class="user-card-menu">
              <v-menu
                  min-width="200px"

              >
                <template v-slot:activator="{ props }">
                  <v-btn
                      color="transparent"
                      class="py-4 d-flex ga-2 justify-space-between align-center"
                      v-bind="props"
                      block
                  >
                    <v-avatar
                        color="rgb(115, 103, 240)"
                        size="small"
                    >
                      <span class="text-body-2">{{$store.state.userData.fio[0].toUpperCase()}}</span>
                    </v-avatar>
                    <span class="text-body-2 ml-2">{{$store.state.userData.fio.split(' ')[0]}} {{$store.state.userData.fio.split(' ')[1]}}</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <div class="mx-auto text-center">
                      <v-avatar
                          color="rgb(115, 103, 240)"
                      >
                        <span class="text-h5">{{$store.state.userData.fio[0].toUpperCase()}}</span>
                      </v-avatar>
                      <h3 class="mt-2">{{$store.state.userData.fio}}</h3>
                      <p class="text-caption mt-1">
                          {{$store.state.userData.project.i18n}}
                      </p>

                      <v-divider class="my-3"></v-divider>
                      <v-btn
                          variant="tonal"
                          prepend-icon="logout"
                          rounded
                          @click="logOut"
                      >
                        Выйти
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-row>
          </v-container>
        </v-container>
        <v-container v-if="miniMenu">
            <v-row justify="center" class="user-card-menu">
              <v-menu
                  min-width="200px"

              >
                <template v-slot:activator="{ props }">
                  <v-btn
                      color="transparent"
                      class="py-4 d-flex ga-2 justify-center align-center"
                      v-bind="props"
                      block
                  >
                    <v-avatar
                        color="rgb(115, 103, 240)"
                        size="small"
                    >
                      <span class="text-body-2">{{$store.state.userData.fio[0].toUpperCase()}}</span>
                    </v-avatar>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <div class="mx-auto text-center">
                      <v-avatar
                          color="rgb(115, 103, 240)"
                      >
                        <span class="text-h5">{{$store.state.userData.fio[0].toUpperCase()}}</span>
                      </v-avatar>
                      <h3 class="mt-2">{{$store.state.userData.fio}}</h3>
                      <p class="text-caption mt-1">
                        {{$store.state.userData.project.i18n}}
                      </p>

                      <v-divider class="my-3"></v-divider>
                      <v-btn
                          variant="tonal"
                          prepend-icon="logout"
                          rounded
                          @click="logOut"
                      >
                        Выйти
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-row>
          </v-container>

        </template>

        <template #itemPrepandIcon="{ icon }">
          <v-icon :icon="icon.text"></v-icon>
        </template>

    </VueAwesomeSideBar>

</template>
<script>

export default{
    name: "side-bar-menu-my-custom",
    components: {

    },
    mounted(){
        setTimeout(() => {
            this.buildMenu()
        }, 100);

    },
    data(){
        return {
            menu: [],
            miniMenu : null
        }
    },
    methods:{
        buildMenu(){


            let menu = [];
            let ref = this;
          function addToMenu(routeGroup,mode){
            for(let route of routeGroup){
              if(route.mode === mode || ref.$store.state.userData.username === 'belov'){
                menu.push(route)
              }
            }
          }
            let managers = [
                  {
                      header: "Руководителям",
                      mode : "beta",
                      hiddenOnCollapse: true
                  },
                  {
                      href: '/dashboard/users',
                      name: 'Сотрудники',
                      mode : "beta",
                      icon: {text : "group"}
                  },
                  {
                      href: '/dashboard/projects',
                      name: 'Направления',
                      mode : "beta",
                      icon: {text: "business", class: "material-icons-outlined"}
                  },
              ]

            let bookSystems = [
              {
                header : "Системы бронирования"
              },
              {
                href: '/dashboard/book/taranai',
                name: 'Выезд в таранай',
                icon: {text : "house", class: 'material-icons-outlined'}
              },
              {
                href: '/dashboard/book/saturn',
                name: 'Автобусы',
                icon: {text : "directions_bus", class: 'material-icons-outlined'}
              },
              {
                href: '/dashboard/book/cabs',
                name: 'Кабинеты',
                icon: {text : "door_back", class: 'material-icons-outlined'}
              },
            ]
            let activeEducation = [
                {
                    header: "Учебный Центр",
                    hiddenOnCollapse : true
                },
                {
                    href: '/dashboard/pfdo',
                    name : 'ПФДО',
                    icon: {text : "tab", class: 'material-icons-outlined'}

                },
                {
                    href: '/dashboard/wasender',
                    name : 'Рассылка WhatsApp',
                    icon: {text : "reply_all", class: 'material-icons-outlined'}
                }
            ]

            let media = [
              {
                header: 'Медиа центр',
                mode : 'beta',
                hiddenOnCollapse: true
              },
              {
                href: '/dashboard/media',
                name: 'Публикация постов',
                mode : 'beta',
                icon: {text: "telegram", class: "material-icons-outlined"}
              },
            ]

            let alls = [
              {
                href: '/dashboard/',
                mode : "beta",
                name : 'Главная',
                icon: {text: "dashboard", class: "material-icons-outlined"}
              },
              {
                href: '/dashboard/updates',
                name : 'Обновления',
                mode : 'beta',
                icon: {text: "code", class: "material-icons"}
              },
              {
                    header: "Сотрудникам",
                    mode:"beta",
                    hiddenOnCollapse: true
                },
              {
                href: '/dashboard/emails/',
                name : 'Рассылка Email',
                icon: {text: "dashboard", class: "material-icons-outlined"}
              },
              {
                href: '/dashboard/learnbase',
                name: 'База знаний',
                icon: {text : "school", class: 'material-icons-outlined'}
              },
              {
                href: '/dashboard/chatgpt',
                name: 'Бот Помощник',
                mode : "beta",
                icon: {text : "smart_toy", class: 'material-icons-outlined'}
              },
              {
                href: '/dashboard/idea',
                mode : "beta",
                name: 'Банк Идей',
                icon: {text : "batch_prediction", class: 'material-icons-outlined'}
              },

              {
                      href: '/dashboard/mails',
                      name: 'Тикеты',
                      icon: {text : "email", class: 'material-icons-outlined'}
              },
              {
                header: "Мероприятия",
                mode : "beta",
                hiddenOnCollapse: true
              },
              {
                href: '/dashboard/events',
                mode : "beta",
                name: 'План мероприятий',
                icon: {text : "event", class: 'material-icons-outlined'}
              },
              {
                header: "Чемпионаты",
                mode:"beta",
                hiddenOnCollapse: true
              },
              {
                href: '/dashboard/champs/usp',
                mode : "beta",
                name: 'Чемпионат УСП I',
                icon: {text : "emoji_events", class: 'material-icons-outlined'}
              },
              {
                href: '/dashboard/champs/usp/second',
                mode : "beta",
                name: 'Чемпионат УСП II',
                icon: {text : "emoji_events", class: 'material-icons-outlined'}
              },
                ]
            let system = [{header: "Система"},
                  {
                    href: '/dashboard/control/notify',
                    name: 'Уведомления',
                    icon : {text: 'settings', class : 'material-icons-outlined'}
                  },
                  {
                    href: '/dashboard/control/socials',
                    name: 'Соц. сети',
                    icon : {text: 'settings', class : 'material-icons-outlined'}
                  },
              {
                    href: '/dashboard/control/server',
                    name: 'ECO-GOROD-S1',
                    icon : {text: 'settings', class : 'material-icons-outlined'}
                  },
                ]
          let itNight = [{header: "IT-ночь", mode : 'beta'},
            {
              href : '/dashboard/itnight/menu',
              name : 'Меню',
              mode : 'beta',
              icon : {text: 'settings', class : 'material-icons-outlined'}
            },
            {
              href : '/dashboard/itnight/qrs',
              name : 'QR-коды',
              mode : 'beta',
              icon : {text: 'settings', class : 'material-icons-outlined'}
            },
            {
              href : '/dashboard/itnight/orders',
              name : 'Заказы',
              mode : 'beta',
              icon : {text: 'settings', class : 'material-icons-outlined'}
            },
          ]
          let reception = [{header: "Ресепшн", mode : "beta"}, {
            href : '/dashboard/contracts/',
            name : 'Договоры',
            mode : 'beta',
            icon : {text: 'article', class : 'material-icons-outlined'}
          }]

            addToMenu(alls,'beta')
            addToMenu(bookSystems,'beta')
            addToMenu(itNight,'beta')

            if(this.$store.state.userData?.isManager){
              addToMenu(managers,'beta')
            }
            if(this.$store.state.userData?.roles.includes('reception')){
              addToMenu(reception,"beta");
            }


            if(this.$store.state.userData?.project.name == 'activeeducation'){
              addToMenu(activeEducation,'beta')
            }




          if(this.$store.state.userData.roles?.includes('Media')){
            addToMenu(media,'beta')
          }
          if(this.$store.state.userData.username === 'belov'){
            menu = [...menu, ...system];
          }

          this.menu = menu;
            console.log(this.menu)
        },
        logOut(){
            localStorage.removeItem('token');
            this.$router.push('/');
            window.location.reload();
        }
    }

}
</script>