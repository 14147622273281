const axios = require('axios').default
const { default: store } = require('@/store');
const EventEmmiter = require('events');

let {useToast} = require('vue-toastification')

/*eslint-disable*/
function toCase(str, choice) {

    var strPub = { // правила для окончаний
        "а": ["ы", "е", "у", "ой", "е"],
        "(ш/ж/к/ч)а": ["%и", "%е", "%у", "%ой", "%е"],
        "б/в/м/г/д/л/ж/з/к/н/п/т/ф/ч/ц/щ/р/х": ["%а", "%у", "%а", "%ом", "%е"],
        "и": ["ей", "ям", "%", "ями", "ях"],
        "ый": ["ого", "ому", "%", "ым", "ом"],
        "ое": ["ого",'ому','%', 'ым', 'ом'],
        "ие": ["я", "ю", "я", "ем", "е"],
        "й": ["я", "ю", "я", "ем", "е"],
        "о": ["а", "у", "%", "ом", "е"],
        "с/ш": ["%а", "%у", "%", "%ом", "%е"],
        "ы": ["ов", "ам", "%", "ами", "ах"],
        "ь": ["я", "ю", "я", "ем", "е"],
        "уль": ["ули", "уле", "улю", "улей", "уле"],
        "(ч/ш/д/т)ь": ["%и", "%и", "%ь", "%ью", "%и"],
        "я": ["и", "е", "ю", "ей", "е"]
    },
    cases = { // номера для падежей, не считая Именительный
        "р": 0,
        "д": 1,
        "в": 2,
        "т": 3,
        "п": 4
    },
    exs = { // исключения, сколько символов забирать с конца
        "ц": 2,
        'ое' : 2,
        'ие' : 2,
        "ок": 2
    },
    lastIndex,reformedStr,forLong,splitted,groupped,forPseudo;
    for(var i in strPub){
        if(i.length > 1 && str.slice(-i.length) == i){ // для окончаний, длиной >1
            lastIndex = i;
            reformedStr = str.slice(0, -lastIndex.length);
            break;
        }
        else if(/[\(\)]+/g.test(i)){
            i.replace(/\(([^\(\)]+)\)([^\(\)]+)?/g, function(a, b, c){
                splitted = b.split("/");
                for(var o = 0; o < splitted.length; o++){
                    groupped = splitted[o] + c;
                    strPub[groupped] = strPub[i];
                    if(str.slice(-groupped.length) == groupped){
                        for(var x = 0, eachSplited = strPub[groupped];x < eachSplited.length; x++){
                            eachSplited[x] = eachSplited[x].replace("%", splitted[o]);
                        }
                        reformedStr = str.slice(0, -groupped.length);
                        forPseudo = groupped;
                    }
                }
            })
        }
        else{ // дефолт
            lastIndex = str.slice(-1);
            reformedStr = str.slice(0, -(forPseudo || lastIndex).length);
        }
        if(/\//.test(i) && !(/[\(\)]+/g.test(i)) && new RegExp(lastIndex).test(i))forLong = i; // группированные окончания, разделающиеся слешем
        for(var o in exs)
            if(str.slice(-o.length) == o)reformedStr = str.slice(0, -exs[o]);
        }
        return reformedStr + strPub[(forPseudo || forLong || lastIndex)][cases[choice]].replace("%", lastIndex)
    }

function toCaseMulti(word,choice){
    let result = [];
    for(let i of word.split(' ')){
        result.push(toCase(i,choice))
    }
    return result.join(' ')
}
class GorodOstrov extends EventEmmiter{
    constructor(){
        super();
        this.bas = 'https://api.городостров.рф';
        this.base = `${this.bas}/`;
        this.host = this.base + 'api/v1';
        let op = false
        this.socket = new WebSocket('wss://api.городостров.рф/websocket');
        console.log(this.socket)
        let reconnection = false;
        this.socket.onclose = ()=>{
            op = true
            useToast().error('Соединение с сервером потеряно. Если произошел сбой, то востановление займет несколько минут')
            reconnection = setInterval(()=>{
                this.socket = new WebSocket('wss://api.городостров.рф/websocket')
            },5000)
        }
        this.socket.onopen = ()=>{
            if(op) useToast().success('Соединение с сервером восстановлено!')
            clearInterval(reconnection)
        }
        this.socket.onmessage = async (message)=>{
            let object = JSON.parse(message.data);
            if(object.action == 'mail'){
                // USER MAIL
                if(object.to.includes('user:')){
                    let login = object.to.split("user:")[1]
                    if(login === store.state.userData.username){
                        let speechURL = await this.endpoint('get',encodeURI(`/speech/?text=Вам поступило новое обращение`))
                        let notify = new Audio(`${this.base}/${speechURL.audio}`);
                        notify.play();
                        console.log(object)
                        this.emit('rerender-mails')
                        new Notification(object.fromtUser[0].fio, {
                            body : object.message,
                            badge: "ECO",
                        })
                    }
                }

                if(object.from === store.state.userData.username){

                    this.emit('rerender-mails')
                }
                // ROLE MAIL
                if(object.to.includes('role:')){
                    let role = object.to.split("role:")[1]
                    if(role === 'IT' && store.state.userData.roles.includes('IT')){
                        let speechURL = await this.endpoint('get',encodeURI(`/speech/?text=Поступило новое обращение в АйТи отдел от ${toCaseMulti(object.fromUserProject.i18n,'р')}. Пользователь: ${object.fromtUser[0].fio}`))
                        let notify = new Audio(`${this.base}/${speechURL.audio}`);
                        notify.play();
                        useToast().info(object.message)
                    }
                }

            }

            if(object.action == 'itnightorder'){
                this.emit('itnightorder');
                useToast().info("Новый заказ на IT-ночь")
                let speechURL = await this.endpoint('get',encodeURI(`/speech/?text=Оформлен новый заказ еды на айти ночь`))
                let notify = new Audio(`${this.base}/${speechURL.audio}`);
                notify.play();
                if(Notification){
                    new Notification("Новый заказ на IT-ночь")
                }
            }
        }
        this.socket.onopen = ()=>{
            this.socket.send({
                action : 'connected'
            })
        }


    }


    async getStatus(){
        try {
            let req = await axios.get(this.host + "/status");
            return true;
        }
        catch (e) {
            console.log(e)
            return false;
        }

    }
    endpoint(method,endpoint,body,isUpload = false, files = [], fileName = 'image'){
        try {
            return new Promise((resolve)=>{
                let ref = this;
                async function exec(){
                    let headers = {}

                    if(localStorage.getItem("token")){
                        headers.authorization = `Bearer ${localStorage.getItem("token")}`;
                    }
                    if(isUpload) headers['Content-Type'] = 'multipart/form-data'
                    if(method == 'get'){
                        try {
                            let req = await axios.get(ref.host + endpoint, {
                                headers : headers
                            });
                            if(req.status != 200){
                                resolve("errorserver");
                            }
                            resolve(req.data);
                        } catch (error) {
                            return false;
                        }
                    }
                    else{
                        let form = new FormData();
                        for(let data in body){
                            form.append(data,body[data]);

                        }
                        if(files){
                            for(let file of files){
                                form.append(fileName,file);
                            }
                        }
                        let req = await axios.post(ref.host + endpoint,form, {
                            headers : headers
                        });
                        resolve(req.data);
                    }
                }
                exec();
            })
        }
        catch (e) {
            console.log(e)
        }

    }
    UITour(page){
        if(localStorage.getItem('tour')){
            let parsed = JSON.parse(localStorage.getItem('tour'));
            if(parsed.pages.includes(page)){
                return true;
            }
            else{
                parsed.pages.push(page);
                localStorage.setItem('tour',JSON.stringify(parsed));
                return false;
            }

        }
        else{
            localStorage.setItem('tour',JSON.stringify({pages : [page]}))
            return false;
        }
    }


}

module.exports = {GorodOstrov}