<template>
  <v-dialog v-model="showNotify">
    <v-card width="30vw" class="mx-auto">
    <v-window  v-model="onboarding">
      <v-window-item v-for="n in notify.length" :key="n">
        <v-card
            class="mx-auto"
            width="30vw"
            color="white"
        >
          <v-card-item>
            <v-card-title>
              {{ notify[n-1].title }}
            </v-card-title>
          </v-card-item>

          <v-card-text class="notify-content wrapped-text">
            {{
              notify[n-1].content
            }}
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showNotify = false" v-if="n == notify.length">Закрыть</v-btn>
            <v-btn @click="onboarding++" v-if="n < notify.length">Далее</v-btn>
          </v-card-actions>
        </v-card>
      </v-window-item>
    </v-window>
    </v-card>
  </v-dialog>
  <SidebarVue v-if="$store.state.userData != null"></SidebarVue>
  <router-view/>
</template>
<script>
import SidebarVue from './components/Sidebar.vue'

export default{
  name : "App",
  components: {
    SidebarVue
  },
  data(){
    return {
      showNotify : false,
      onboarding : 0,
      notify : []
    }
  },
  async mounted(){
    if(!await this.$api.getStatus()){
      let to = ''
      if(this.$route.path){
        to = `?to=${this.$route.path}`;
      }
      this.$router.push('serverfailure' + to);
    }
    if(localStorage.getItem("token")){
      try {
        let req = await this.$api.endpoint("post","/auth/check")
        if(req.message != "ok"){
          this.$router.push('/');
        }
        else{
          this.$store.state.userData = req.users;
          let getNotify = await this.$api.endpoint("get",'/users/notify/' + this.$store.state.userData.username);
          let persitentNotifies = [];

          for(let notify of getNotify){
            if(notify.type === 'update' || notify.type === 'notify') {
              persitentNotifies.push(notify)
            }
            else{
              if(notify.type.includes('native')){
                let type = notify.type.split('native')[1].toLowerCase();
                this.$toast()[type](notify.content)
              }
            }
          }
          console.log(this.$OneSignal)
          this.$OneSignal.User.PushSubscription.optIn();
          for(let role of req.users.roles){
            this.$OneSignal.User.addTag(`is_${role.toLowerCase()}`,"true");
          }

          this.$OneSignal.Notifications.addEventListener("foregroundWillDisplay",(event)=>{
            this.$toast().info(event.notification.body)
          })

          if(persitentNotifies.length > 0){
            this.notify = persitentNotifies;
            this.showNotify = true;
          }

        }

      } catch (error) {
        void(error)
        this.$router.push('/');
      }
      
      
    }
    else{
      this.$router.push('/');
    }
    
    
  }
}
</script>
<style>

</style>
