import { createStore } from 'vuex'

export default createStore({
  state: {
    userData: null,
    theme: 'dark',
    defaultBlockColorDark : '#2f3349',
    defaultBlockLightDark : '#fff',

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
